import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span>I don't know shit</span>
        <span>I don't do shit</span>
        <a href="https://twitter.com/ftd_ftd">Twitter</a>
        <a href="https://mastodon.social/@ftd_ftd">Mastodon</a>
        <a href="https://www.instagram.com/ftdftd/">Instagram</a>
        <a href="https://letterboxd.com/ftdftd/">Letterboxd</a>
      </header>
    </div>
  );
}

export default App;
